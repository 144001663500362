.ServiceCard_container{
    width: 49%;
    height: 400px;
    min-width: 445px;
    /* border: 1px solid rgb(62 95 255); */
    border-radius: 20px;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    overflow: hidden;
    opacity: 1;
    margin-top: 0px;
    transition: 0.5s;
    flex-shrink: 0;
    cursor: pointer;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
}

.ServiceCard_start_position{
    opacity: 0;
    margin-top: 50px;
}

.ServiceCard_mask{
    overflow: hidden;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(1px);
    color: white;
    display: flex;
    flex-direction: column;
    transition: 0.3s;
}

.ServiceCard_mask:hover{
    background-color: rgba(0, 0, 0, 0.7);
    transition: 0.3s;
}

.ServiceCard_horizontal{
    width: 100%;
    height: 1px;
    background-color: rgb(62 95 255);
    margin-bottom: 15px;
    border-radius: 5px;
}

.ServiceCard_serviceName{
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-style: oblique;
    font-weight: 500;
    margin-top: auto;
}

.ServiceCard_plusses{
    gap:10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    transition: 0.4s;
}

.ServiceCard_plus{
    width: 100%;
    height: fit-content;
    text-wrap: wrap;
    word-wrap: break-word;
    display: flex;
    overflow: hidden;
}

.ServiceCard_dot{
    margin-top: 8.5px;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 50%;
    flex-shrink: 0;
    margin-right: 7px;
}

.ServiceCard_plus_text{
    height: fit-content;
    text-wrap: wrap;
    word-wrap: break-word;
    overflow: hidden;
    font-weight: 300;
    line-height: 20px;
    font-size: 16px;
}