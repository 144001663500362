body{
    padding: 0;
    margin: 0;
}

html{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

html::-webkit-scrollbar{
    display: none;
}