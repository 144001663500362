.GalleryPage_container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
}

.GalleryPage_photo{
    position: absolute;
    width: 33%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    animation: folding 0.5s forwards;
}

.GalleryPage_photo:hover{
    animation: expansion 0.5s;
    animation-fill-mode: forwards;
}

@keyframes expansion{
    from{
        z-index: 1;
    }
    1%{
        z-index: 10;
    }
    to{
        z-index: 10;
        width: 100%;
    }
}
@keyframes folding{
    from{
        z-index: 10;
        width: 100%;
    }
    99%{
        z-index: 1;
    }
    to{
        width: 33%;
        z-index: 1;
    }
}

#GalleryPage_photo_1{
    background-position-x:left;
    background-position-y: center;
}
#GalleryPage_photo_2{
    left: 50%;
    transform: translateX(-50%);
    background-position-x: center;
    background-position-y: center;
}
#GalleryPage_photo_3{
    left: auto;
    right: 0px;
    background-position-x: right;
    background-position-y: center;
}


.GalleryPage_container{
    position: absolute;
    transition: 0.8s;
    width: 100%;
    flex-shrink: 0;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
}
