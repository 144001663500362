.Gallery_main_container{
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.Gallery_photo_container{
    display: flex;
    height: 70dvh;
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    opacity: 1;
    transform: translateX(0%);
    transition: 1s;
}


.Gallery_photo_container_hidden{
    opacity: 0;
    transform: translateX(-70%);
}


.Gallery_description_container{
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    border-radius: 10px;
    overflow: hidden;
    opacity: 1;
    transform: translateX(0%);
    transition: 1s;
    overflow-y: scroll;
    cursor: pointer;
}

.Gallery_description_container::-webkit-scrollbar{
    display: none;
}

.Gallery_description_container_hidden{
    opacity: 0;
    transform: translateX(-70%);
}

.Gallery_prev_page{
    width: 50%;
    height: 100%;
    background-image: 
    linear-gradient(to right, 
    rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0));
    opacity: 0;
    transition: 0.5s;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.Gallery_prev_page:hover{
    opacity: 1;
    transition: 0.5s;
}

.Gallery_next_page{
    width: 50%;
    height: 100%;
    background-image: 
    linear-gradient(to left, 
    rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0));
    opacity: 0;
    transition: 0.5s;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.Gallery_next_page:hover{
    opacity: 1;
    transition: 0.5s;
}

.page_change_img{
    user-select: none;
    width: 40px;
    height: 40px;
}

#page_change_left{
    transform: rotate(180deg);
    margin-left: 8px;
}

#page_change_right{
    margin-left: auto;
    margin-right: 8px;
}



.Gallery_description_container:hover{
    background-color: rgba(0, 0, 0, 0.65);
    transition: 0.5s;
}


.Gallery_description{
    margin-top: auto;
    margin-bottom: 20px;
    width: 90%;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    color: white;
    opacity: 0;
    transition: 0.5s;
    padding-top: 20px;
}

.Gallery_description_container:hover .Gallery_description{
    opacity: 1;
    transition: 0.5s;
}

.blue{
    color: rgb(166, 181, 255);
    font-weight: 500;
}

.Gallery_page_change_container{
    width: 100%;
    height: 40px;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.Gallery_pages{
    width: fit-content;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
}

.Gallery_page_border_active{
    border: 3px solid rgb(62 95 255);
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
}

.Gallery_page_border{
    border: 3px solid rgb(154, 154, 154);
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    cursor: pointer;
}

.Gallery_page_border:hover .Gallery_page{
    background-color: rgb(154, 154, 154);
    transition: 0.3s;
}

.Gallery_page_active{
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgb(62 95 255);
    transition: 0.3s;
}

.Gallery_page{
    width: 6px;
    height: 6px;
    border-radius: 50%;
    transition: 0.3s;
}

.change_page_button{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
    transition: 0.3s;
}

.change_page_button:hover{
    /* background-color: rgba(0, 0, 0, 0.15); */
    transform: scale(110%);
    transition: 0.3s;
}

.change_page_button_icon{
    width: 100%;
    height: 100%;
}

#change_page_button_next .change_page_button_icon{
    transform: rotate(180deg);
}