.TextHeader_container{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.TextHeader{
    position: absolute;
    font-size: 40px;
    font-weight: 700;
    color: #004d76;
    left: 0px;
    opacity: 100%;
    transition: 0.7s;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
}

.addText{
    margin-top: 56px;
    font-size: 20px;
    font-weight: 400;
    position: absolute;
    left: 0px;
    opacity: 100%;
    transition: 0.7s;
}

.toLeft{
    left: -50px;
    opacity: 0%;
}
.toRight{
    left: 80px;
    opacity: 0%;
}