.Vacancy_container{
    position: relative;
    width: 100%;
    top: 0px;
    opacity: 1;
    transition: 0.5s;
}


.Vacancy_container_hidden{
    top: 100px;
    opacity: 0;
}

.vacancy_text_header{
    text-align: center;
    font-size: 24px;
    font-weight: 400;
}

.vacancy_header{
    text-align: center;
    margin-top: 24px;
    font-size: 24px;
    font-weight: 600;
    color: rgb(62 95 255);
}

.vacancy_text{
    text-align: center;
    margin-top: 12px;
    font-size: 24px;
    font-weight: 400;
}

.vacancy_footer{
    margin-top: 36px;
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
}