.HeaderButton_container{
    width: fit-content;
    height: fit-content;
    padding: 0px 5px 0 5px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    color: #004d76;
}

.HeaderButton_underline{
    width: 0%;
    height: 1px;
    background-color: rgb(62 95 255);
    border-radius: 1px;
    transition: 0.3s;
}

.HeaderButton_container:hover .HeaderButton_underline{
    width: 100%;
    transition: 0.3s;
}
