.Number_container{
    margin-top: 60px;
    width: fit-content;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-grow: 1;
    flex-basis: auto;
}

.Number_number{
    padding: 0px 10px 0 10px;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    color: rgb(62 95 255);
}

.Number_horizontal{
    width: 100%;
    height: 1px;
    background-color: rgb(62 95 255);
}

.Number_text{
    font-size: 20px;
    padding: 0 10px 0 10px;
    text-align: center;
    font-weight: 500;
}