.Contact_container{
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    transition: 0.5s;
    margin-left: 0px;
    opacity: 1;
    cursor: pointer;
    position: relative;
}

.Contact_text{
    font-size: 30px;
    font-weight: 600;
}

.Contact_start_position{
    opacity: 0;
    margin-left: -50px;
    transition: 0.5s;
}

.Contact_hint{
    width: fit-content;
    padding: 5px;
    border: 1px solid rgb(62 95 255);
    border-radius: 5px;
    position: absolute;
    right: 0px;
    transform: translateX(150%);
    opacity: 0;
    transition: 0.3s;
    color: rgb(62 95 255);
}

.Contact_text:hover+.Contact_hint{
    transform: translateX(125%);
    opacity: 1;
    transition: 0.3s;
}