.Footer_container{
    margin-top: auto;
    margin-bottom: 0px;
    width: 100%;
    height: 70px;
    border-top: 1px solid black;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
}

.Footer_line{
    width: calc(100% - 80px);
    padding: 0 40px 0 40px;
    display: flex;
    justify-content: space-between;
}

.Footer_text{
    font-size: 14px;
}

#Footer_address{
    color: #0088ff;
}