.Header_info{
    width: auto;
    height: 50px;
    display: flex;
    padding: 0px 40px 0 40px;
    justify-content: space-between;
    align-items: center;
}

.Header_info_logo_container{
    display: flex;
    gap: 16px;
    align-items: center;
}

.Header_info_logo_text{
    font-family: "Dela Gothic One", sans-serif;
    font-size: 24px;
    color: #004d76;
}

.Header_info_logo_img{
    width: 50px;
    height: 50px;
}

.Header_info_contacts{
    display: flex;
    width: fit-content;
    height: 35px;
    align-items: center;
    gap: 40px;
}

.Header_info_contact{
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
}

.Header_info_contact_icon{
    width: 35px;
    height: 35px;
}

.Header_info_contact_text{
    margin-left: 8px;
}

.Header_container{
    width: 100%;
    height: 40px;
    position: sticky;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(7px);
    z-index: 2;
}