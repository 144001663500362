.MainPage_container{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MainPage_company_container{
    position: relative;
    width: 100%;
    height: fit-content;
    background-repeat: no-repeat;
    background-size: contain;
}

.MainPage_company_background{
    width: 100%;
    height: 80dvh;
    background-repeat: no-repeat;
    background-size: cover;
}

.MainPage_company_mask{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(5px);
}

.MainPage_company_text_container{
    width: 80%;
    max-width: 1200px;
    height: fit-content;
    display: flex;
    gap: 40px;
    overflow: visible;
}

@keyframes fadeIn{
    from{
        opacity: 0;
        margin-top: 80px;
    }
    20%{
        opacity: 0;
    }
    90%{
        margin-top: 0px;
    }
    to{
        opacity: 1;
    }
}

.MainPage_company_header{
    margin-top: 0px;
    font-size: 48px;
    font-weight: 700;
    color: #004d76;
    width: 290px;
    animation-name: fadeIn;
    animation-duration: 1.5s;
    opacity: 1;
    animation-iteration-count: 1;
}

.MainPage_company_text{
    margin-top: 0px;
    font-size: 20px;
    font-weight: 500;
    animation-name: fadeIn;
    animation-duration: 1.5s;
    opacity: 1;
    animation-iteration-count: 1;
}

.MainPage_company_numbers_container{
    width: 80%;
    height: fit-content;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
}

.MainPage_company_numbers_main{
    display: flex;
    justify-content: space-between;
    gap: 40px;
}

.MainPage_services_container{
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    width: 80%;
}

.MainPage_services_main{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 60px;
    position: relative;
    height: fit-content;
    min-height: 500px;
}

.MainPage_connect_container{
    margin-top: 100px;
    width: 80%;
    display: flex;
    flex-direction: column;
}

.MainPage_connect_main{
    margin-top: 60px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    gap: 30px   ;
}

.MainPage_photos_container{
    width: 80%;
    height: fit-content;
}

.MainPage_photos_description{
    margin-top: 20px;
    font-size: 20px;
}

.MainPage_vacancy_container{
    margin-top: 100px;
    width: 80%;
    position: relative;
}

.MainPage_vacancy_header{
    margin-top: 8px;
    font-size: 20px;
    font-weight: 400;
    position: absolute;
}

.MainPage_vacancy_main{
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.connect_images_container{
    margin-top: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.connect_image_container{
    width: 49%;
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.connect_text{
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    width: 100%;
    min-height: 49px;
}

.connect_scheme{
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-top: 8px;
    cursor: zoom-in;
}

#connect_map{
    font-size: 20px;
    font-weight: 600;
    object-fit: cover;
    height: 100%;
}

.connect_full_img{
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    cursor: zoom-out;
}

.full_map{
    width: 100%;
    height: auto;
}
